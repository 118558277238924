import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { CloseOutlined } from '@material-ui/icons';
import Layout from '../../components/v2/Layout';
import { getEmoriDiary, getEmoriRelationDiary } from '../../apis/emori';
import { renderDate, renderDateTime } from '../../helpers/date';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    height: '100%',
    display: 'inline-block',
    backgroundColor: 'white',
    borderRadius: '10px',
    paddingBottom: '22px',
    paddingTop: '20px',
  },
  month: {
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    paddingBottom: '8px',
  },
  table: {
    maxWidth: '100%',
    marginBottom: '10px',
  },
  tableCell: {
    padding: '1px 10px 0 10px',
    border: 0
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'left',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#3D6AAE',
  },
  selectedEmoriIcon: {
    marginTop: '9px',
    width: theme.spacing(14.75),
    height: theme.spacing(14.625),
  },
  feelingChoose: {
    fontSize: '17px',
    fontWeight: 'bold',
    color: '#3D6AAE',
  },
  closeTable: {
    padding: '1px 10px 0 10px',
    border: 0,
    display: 'flex',
    justifyContent: 'right',
  },
  emoriText: {
    display: 'flex',
    justifyContent: 'center',
    margin: '3px',
  },
  emoriList: {
    marginRight: '2px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0.5),
      width: theme.spacing(11.5),
      height: theme.spacing(2.75),
    },
    borderRadius: '12px',
  },
  emoriIcon: {
    width: '61px',
    height: '62px',
  },
}))

export default function SummaryDate(props) {
  const classes = useStyles();
  const [ emoriList, setEmoriList] = React.useState(null);
  const [ open, setOpen ] = React.useState(false);
  const [ selected, setSelected ] = React.useState(null);
  const { month, year } = useSelector(state => state.emori);
  const day = props.location.state?.day;
  const room = props.location.state?.room;
  const member = props.location.state?.member;

  React.useEffect(() => {
    emori();
  }, []);

  const emori = async () => {
    try {
      // const res = await getEmoriDiary(year, month, day);
      let res = {};
      if (member !== 'undefined' || room !== 'undefined') {
        res = await getEmoriRelationDiary(room, member, year, month, day);
      } else {
        res = await getEmoriDiary(year, month, day);
      }
      setEmoriList(res);
    } catch (e) {}
  }

  const onClickEmori = (emoji) => {
    setOpen(true);
    setSelected(emoji);
  }

  if (!emoriList) return false;

  return (
    <Layout bg='bg3' title='date'>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth='md' PaperProps={{ style: {borderRadius: '29px', marginBottom: '5px'}}}>
        <DialogContent>
          {
            selected ?
              <Table className={classes.table} size='small'>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <Typography className={classes.dialogTitle}>{renderDateTime(selected.created)}</Typography>
                    </TableCell>
                    <TableCell className={classes.closeTable}>
                      <IconButton aria-label='close' onClick={() => setOpen(false)}>
                        <CloseOutlined />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='center' colSpan={2} className={classes.tableCell}>
                      <img className={classes.selectedEmoriIcon} src={`${selected.emori.image}`} alt={`${selected.emori.name}`}/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='center' colSpan={2} className={classes.tableCell}>
                      <p className={classes.feelingChoose}>{selected.note}</p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            : <></>
          }
        </DialogContent>
      </Dialog>
      <Typography className={classes.month}>{renderDate(emoriList[0].created)}</Typography>
      <div className={classes.paper}>
        <Grid container spacing={1}>
          {
            emoriList.map((emoji, index) => {
              return (
                <Grid item xs={3} key={index}>
                  <div className={classes.emoriList} onClick={() => onClickEmori(emoji)}>
                    <img className={classes.emoriIcon} src={`${emoji.emori.image}`} alt={`${emoji.emori.name}`} />
                    <Typography gutterBottom color='textPrimary' className={classes.emoriText}>{emoji.emori.name}</Typography>
                  </div>
                </Grid>
              )
            })
          }
        </Grid>
      </div>
    </Layout>
  )
}